/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react'
import { Global, css } from '@emotion/core'

export const wrapRootElement = ({element}) => (
    <>
    <Global styles={css`
        html, body, #___gatsby, #___gatsby > div{
            width:100%;
            height:100%;
            margin:0;
            padding: 0;
        }
        *{
            font-family:'Roboto Mono';
        }
        h1,h2,h3,h4,h5,h6{
            font-family:'Roboto';
        }
      
    `} />
    {element}
    </>
)
